//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-496:_1636,_295,_3992,_1480,_9293,_9536,_1802,_2719;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-496')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-496', "_1636,_295,_3992,_1480,_9293,_9536,_1802,_2719");
        }
      }catch (ex) {
        console.error(ex);
      }