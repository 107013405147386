//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-496:_4776,_8896,_9556,_1160,_3724,_552,_5591,_4008;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-496')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-496', "_4776,_8896,_9556,_1160,_3724,_552,_5591,_4008");
        }
      }catch (ex) {
        console.error(ex);
      }